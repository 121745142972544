@import "./variables";

.partner_carousel-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;

  .partner_carousel {
    display: flex;
    align-items: center;
    width: 93%;
    margin: auto;
    overflow: hidden;
    border: $bg solid 1px;
    // padding: 2rem;

    .partner_carousel-inner {
      display: flex;
      transition: transform 0.5s ease-in-out;
      gap: 10px;

      .partner_carousel-item {
        min-width: 24%; /* For showing 4 logos at a time */
        transition: all 0.5s;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: $bg solid 2px;
      
        img {
          max-width: 70%;
          height: 50%;
        }
      }
    }
  }
  
  
  .partner_carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba($primary, 0.5);
    color: white;
    border: none;
    padding: 3px 6px;
    cursor: pointer;
    z-index: 1;
    font-size: 24px;
    margin: 0;
  }

  .left-arrow {
    left: 10px;
  }
  
  .right-arrow {
    right: 10px;
  }
}

@media (max-width: 768px) {
  .partner_carousel-container {
    .partner_carousel {
      .partner_carousel-inner {
        gap: 0;
        .partner_carousel-item {
          min-width: 99.5%; /* For showing 1 logo at a time on small screens */
          img {
            max-width: 90%;
            height: 70%;
          }
        }
      }
    }
  }

  /* Adjust arrow positions for smaller screens */
  .partner_carousel-arrow {
    margin: 0 15px; /* Reduce the gap on mobile */
  }
}
