@import "./variables";

.footer {
    background-image: url("../assets/svgs/waterwave.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;

    .content {
        padding: 1.5rem 0 1.5rem 0;
        width: 100%;
        margin: auto;
        background-color: rgba($color: $primary, $alpha: 0.87);
        color: white;
        display: flex;
        flex-direction: column;
        gap:  3rem;
        align-items: center;

        .website {
            width: 85%;
            margin: 1rem auto;
            font-size: large;
            // font-weight: 600;
            display: grid;
            // gap: 20rem;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

            a {
                color: white;
                text-decoration: none;
                line-height: 36px;
                margin-left: 1rem;
            }

            a:hover {
                font-weight: bold;
                // color: $white;
                text-decoration: underline;
            }

            .pages {
                border-left: $lightgray solid 0.5px;
            }

            .pages, .sections {
                display: flex;
                flex-direction: column;
                text-decoration: none;
                border-right: $lightgray solid 0.5px;
            }

            .spages {
                margin-left: 1rem;
                
                a {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            .pages, .sections, .reachus {
                padding-left: 10.3rem;
                
            }

            .reachus {
                border-right: $lightgray solid 0.5px;
                a {
                    line-height: 24px;
                }
                .contsloc {
                    * {
                        width: 75%;
                        margin: auto;
                    }
                    display: flex;

                    svg{
                        width: 1rem;
                    }
                }
            }

            .heads{
                font-weight: bolder;
                font-size: larger;
            }
        }

        .socials {
            font-size: larger;
            display: flex;
            gap: 2rem;

            .socialicon:hover {
                color: $secondary;
            }
        }

        .socials {
            position: relative;
        }
        
        .socials::before,
        .socials::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 130%;
            height: 1px;
            background-color: white;
        }
        
        .socials::before {
            left: -150%;
            transform: translateY(-50%);
        }
        
        .socials::after {
            right: -150%;
            transform: translateY(-50%);
        }

        .logo {
            display: flex;
            justify-content: center;
            img {
                width: 60%;
            }
        }

        .legal {
            width: 85%;
            // border-radius: 25%;
            text-align: center;
            // background-color: $white;
            color: white;
            // padding: 1rem 0 2rem 0;
            .terms {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
                margin-bottom: 2px;

                .dot {
                    width: 10px;
                    height: 10px;
                    background-color: white;
                    border-radius: 50%;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .footer {
        .content {
            .website {
                .pages, .sections, .reachus {
                    padding-left: 0;
                    border: none;
                }
            }
        }
    }
}

@media screen and (max-width: 1120px) {
    .footer {
        .content {
            .website {
                .pages, .sections, .reachus {
                    padding-left: 1.5rem;
                }
            }

            .socials::before,
            .socials::after {
                content: "";
                width: 80%;
            }

            .socials::before {
                left: -120%;
                transform: translateY(-50%);
            }
            
            .socials::after {
                right: -120%;
                transform: translateY(-50%);
            }   

            .legal {
                width: 95%;
                border-radius: 5%;
                padding: 0 0 1rem 0;
                .terms {
                    display: block;
                    .dot {
                        display: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .footer {
        .content {
            .socials::before,
            .socials::after {
                content: "";
                width: 40%;
            }

            .socials::before {
                left: -80%;
                transform: translateY(-50%);
            }
            
            .socials::after {
                right: -80%;
                transform: translateY(-50%);
            }

        }
    }
}

@media screen and (max-width: 900px) {
    .footer {
        .content {
            .socials::before,
            .socials::after {
                content: "";
                width: 20%;
            }

            .socials::before {
                left: -40%;
                transform: translateY(-50%);
            }
            
            .socials::after {
                right: -40%;
                transform: translateY(-50%);
            }

        }
    }
}

@media screen and (max-width: 580px) {
    .footer {
        .content {
            .website {
                grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
                gap: 1rem;
            }
            .socials::before {
                display: none;
            }
            
            .socials::after {
                display: none;
            }

        }
    }
}