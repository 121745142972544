.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__container {
    height: 100%;
    display: flex;
    transition: transform 0.5s ease-in-out; /* Smooth slide */
    width: 100%; /* Make sure the container expands for all images */
  }

  &__image {
    min-width: 100%; /* Each image takes 100% of the container */
    height: 100%;
    background-size: cover;
    background-position: center;
  }

  &__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px 16px;
    cursor: pointer;
    z-index: 10;

    &.prev {
      left: 10px;
    }

    &.next {
      right: 10px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  @media (max-width: 668px) {
    &__btn {
      padding: 6px 12px;
    }
  }
}
