@import "./variables";

.home {
    width: 100%;
    margin: auto;

    h2 {
        text-align: center;
        color: $primary;
    }

    .landingscreen {
        width: 100%;
        min-height:100vh;
        background-image: 
        // linear-gradient(
        //     rgba($color: $primary, $alpha: 0.90), 
        //     rgba($color: $primary, $alpha: 0.75)), 
            // url("../assets/imgs/waterdrop.jpg");
            url("../assets/imgs/waterabout.jpg");
        background-size: cover; /* Ensures the image covers the entire area */
        background-position: center; /* Centers the image within the container */
        background-repeat: no-repeat; /* Prevents the image from repeating */

        .content {
            width: 100%;
            height: 100vh;
            padding-top: 2rem;
            display: flex;
            flex-direction: column; /* Stack items vertically */
            justify-content: center; /* Center vertically */
            align-items: center;
            text-align: center;

            .techimage {
                height: 80%;
                display: flex;
                flex-direction: column; /* Stack items vertically */
                justify-content: center; /* Center vertically */
                align-items: center;
                text-align: center;

                h3 {
                    color: white;
                    text-shadow: 2px 2px black;
                }
                
                img {
                    max-width: 40%;
                    width: 60%;
                    margin-bottom: 2%;
                    min-width: 350px;
                }
                a {
                    color: $white;
                }

                a:hover {
                    color: darken($white, 20%);
                }
            }
        }
    }

    .main1 {
        width: 100%;
        margin: 1rem auto;
        background-image: url("../assets/imgs/technology.jpg");
        background-position: center;
        background-size: contain;

        .main1bg {
            background-color: rgba($color: white, $alpha: 0.94);
            .section1 {
                width: 60%;
                margin: auto;
                min-height:60vh;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
    
                .intro {
                    h2 {
                        color: $primary;
                    }
                    p {
                        font-size: 20px;
                    }
                    .color-animation {
                        background-image: linear-gradient(to right, $accent, $accent 50%, $primary 50%, $primary); /* Adjust colors as needed */
                        background-size: 200% 100%;
                        background-clip: text;
                        color: transparent;
                        animation: color-run 7s linear infinite forwards;
                    }
                    
                    @keyframes color-run {
                        0% {
                            background-position: 100% 0;
                        }
                        100% {
                            background-position: -100% 0;
                        }
                    }
                }
            }
            .section2 {
                width: 100%;
                margin: auto;

                .whatwedo {
                    width: 85%;
                    margin: auto;
                    padding-top: 10px;
                    display: grid;
                    gap: 2px;
                    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                    .service {
                        background-color: $primary;
                        padding: 0 1em;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: white;
                        border-right: white solid 2px;

                        .index {
                            width: 24px;
                            height: 28px;
                            margin-right: 1rem;
                        }
                    }
                    .service:hover {
                        background-color: $accent;
                    }
                }

                .wasrebkpis {
                    width: 100%;
                    margin-top: 70px;
                    padding-top: 50px;
                    background-image: url("../assets/svgs/rectangularwave.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 50vh;
                    display: flex;
                    align-items: center; /* Vertically centers the .items div */
                    justify-content: center; /* Optionally centers the .items div horizontally */
                    color: white;
                    
                    .items {
                        display: grid;
                        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
                        width: 90%; /* Ensures .items fills the container width if needed */
                        margin: auto;
                        height: max-content;
                        
                        .kpi{
                            text-align: center;
                            border-right: white solid 2px;

                            p{
                                font-weight: 800;
                                font-size: 20px;
                            }
                        }
                    }
                }
                
                
    
                // .services {
                //     display: grid;
                //     gap: 2rem;
                //     grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
                    
                //     .service {
                //         background-color: white;
                //         padding: 1rem;
                //         border-radius: 6px;
                //         box-shadow: 6px 6px 12px 1px $gray;
                //         background-image: url("../assets/svgs/hexagonlightblue.svg");
                //         background-position: right 100% bottom 160%;
                //         background-repeat: no-repeat;
    
                //         .title {
                //             border: solid $primary 1px;
                //             border-radius: 15px 0 25px 15px;
                //             display: flex;
                //             gap: 1rem;
    
                //             p {
                //                 margin: 0;
                //                 font-size: larger;
                //                 font-weight: bold;
                //                 color: white;
                //                 background-image: url("../assets/svgs/hexagon.svg");
                //                 background-repeat: no-repeat;
                //                 background-size: contain; /* Ensure the SVG scales appropriately */
                //                 background-position: center; /* Center the background vertically and horizontally */
                //                 padding: 30px;
                //                 background-color: $lightgray;
                //                 border-radius: 15px 1px 1px 15px; 
                //             }
    
                //             h3 {
                //                 margin: auto 0;
                //                 color: $primary; 
                //             }
                //         }
                //     }
                // }
            }
        }
    }

    .main2 {
        width: 85%;
        margin: auto;
        padding: 4rem 0;
        background-image: url("../assets/imgs/ml-brain.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        .main2bg {
            background-color: rgba($color: white, $alpha: 0.92);
            display: flex;
            align-items: center;

            .whyus {
                width: 85%;
                margin: auto;

                .container {
                    color: white;
                    display: grid;
                    grid-template-columns: repeat(auto-fit, minmax(190px, 1fr));

                    .reason {
                        height: 200px;
                        padding: 1rem;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;

                        h3 {
                            margin: 0;
                        }

                        *  {
                            width: 75%;
                        }
                    }

                    .data {
                        background-image: url("../assets//svgs/waterdropnwave.svg");
                        background-position: center;
                        background-size: 100%;
                        background-repeat: no-repeat;
                    }

                    .extra-item {
                        grid-column-start: 1; /* Start from the first column */
                        grid-column-end: -1; /* Span all columns */
                        justify-self: center; /* Center the item horizontally */
                        width: 18%;
                    }
                }
            }
        }
    }

    .main3 {
        width: 85%;
        margin: auto;
        padding: 4rem 0;
        background-image: url("../assets/imgs/ml-brain.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        .main3bg {
            background-color: rgba($color: white, $alpha: 0.92);
            width: 100%;
            margin: auto;

            .allnews {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .main4 {
        // width: 85%;
        // margin: auto;
        // padding: 4rem 0;
        background-image: url("../assets/imgs/ml-brain.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        .main4bg {
            background-color: rgba($color: white, $alpha: 0.92);
            width: 100%;
            margin: auto;

            .partnerss {
                width: 99%;
                margin: auto;
            }

        }
    }

    .centerheading {
        display: flex;
        justify-content: center;

        .decorated-heading {
            position: relative;
            display: inline-block;
        }
        
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $primary;
            transform: translateY(-50%);
        }
        
        .decorated-heading::before {
            left: -40px; 
        }
        
        .decorated-heading::after {
            right: -40px;
        }
    }

}

@media screen and (min-width: 1545px) {
    .home {

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .home {

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }

                        .extra-item {
                            grid-column-start: 1; /* Start from the first column */
                            grid-column-end: -1; /* Span all columns */
                            justify-self: center; /* Center the item horizontally */
                            width: 50%;
                            /* Additional styles for the extra grid item */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .home {
        .landingscreen {   
            .content {
                padding-top: 2rem;
                .techimage {
                    
                    img {
                        // max-width: 1500px;
                        width: 90%;
                        margin-bottom: 12%;
                        // min-width: 350px;
                    }
                }
            }
        }

        .main1 {
            .main1bg {
                .section1 {
                    width: 85%;
                    .intro {
                    }
                }
                .section2 {
                    .services {
                        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
                    }
                }
            }
        }

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen  and (max-width: 830px) {
    .home {
        .main1 {
            .main1bg {
                .section2 {
                    .wasrebkpis {
                        width: 100%;
                        padding-top: 10px;
                        background-color: $primary;
                        height: 70vh;
                        display: flex;
                        align-items: center; /* Vertically centers the .items div */
                        justify-content: center; /* Optionally centers the .items div horizontally */
                        color: white;
                    }
                }
            }
        }
        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .extra-item {
                            grid-column-start: auto;
                            grid-column-end: auto;
                            justify-self: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .home {
        .landingscreen {   
            .content {
                .techimage {
                    img {
                        margin-bottom: 22%;
                    }
                }
            }
        }

        .main1 {
            .main1bg {
                .section1 {
                    width: 85%;
                    .intro {
                    }
                }
                .section2 {
                    .services {
                        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
                    }
                }
            }
        }

        .main2 {
            width: 100%;
            .main2bg {
                .whyus {
                    .container {
                        .reason {
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            height: 10px;
                        }

                        .extra-item {
                            grid-column-start: 1; /* Start from the first column */
                            grid-column-end: -1; /* Span all columns */
                            justify-self: center; /* Center the item horizontally */
                            width: 50%;
                            /* Additional styles for the extra grid item */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 705px) {
    .home {
        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .reason {
                            height: 300px;
                        }
                        .extra-item {
                            grid-column-start: auto;
                            grid-column-end: auto;
                            justify-self: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 640px) {
    .home {
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            width: 3%;
        }

        .landingscreen {   
            .content {
                .techimage {
                    img {
                        margin-bottom: 0;
                        max-width: 100px;
                        min-width: 30%;
                        }
                }
            }
        }

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .nodata {
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}