$primary-color: #00478f;
$secondary-color: #007acb;
$text-color: #333;
$background-color: #f3f7fa;
$border-color: #d1d1d1;

.bio-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  background-color: $background-color;
}

.bio-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
  overflow: hidden;
}

.bio-image {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 20px;
  box-shadow: 0 2px 3px 1px rgba($primary-color, 0.9);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bio-content {
  text-align: center;
  color: $text-color;

  h2 {
    font-size: 2rem;
    color: $primary-color;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.4rem;
    color: $secondary-color;
    margin-bottom: 1.5rem;
  }

  hr {
    width: 60px;
    border: 1px solid $primary-color;
    margin: 1rem auto;
  }

  p {
    text-align: justify;
    font-size: 1.1rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    padding: 0 20px;
  }

  .bio-details {
    text-align: center;
    width: 70%;
    max-width: 700px;
    margin: 0 auto;

    h4 {
      font-size: 1.2rem;
      color: $primary-color;
      margin-bottom: 0.8rem;
    }

    p {
      color: $secondary-color;
      font-style: italic;
      text-align: center;
    }

    ul {
      margin-bottom: 1.5rem;
      padding-left: 20px;

      li {
        font-size: 1.1rem;
        line-height: 1.8;
        list-style: disc;
        padding-left: 5px;
        color: $secondary-color;

        &:before {
          content: none;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .bio-card {
    padding: 20px;

    .bio-content {
      padding: 0;

      h2 {
        font-size: 1.7rem;
      }

      h3 {
        font-size: 1.3rem;
      }

      .bio-details {
        width: 100%;
        padding: 0 10px;
      }
    }
  }
}
