@import "./variables";

.statement-container {
    max-width: 85%;
    margin: 0 auto;
    padding: 60px 20px;
    background-color: #fffefe;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    font-size: large;
  
    .statement-image {
      float: left;
      margin: 0 25px 5px 0;
      width: 300px;
      height: auto;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  
    .statement-text {
      h2 {
        margin-top: 0;
        // color: #1b1b1b;
        color: $primary;
        font-family: 'Roboto', sans-serif;
        font-size: 24px;
      }
  
      p {
        // color: #666;
        text-align: justify;
        line-height: 1.6;
        font-family: 'Open Sans', sans-serif;
        font-size: 20px;
        margin-bottom: 1rem;
      }
    }
  
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  @media screen and (max-width: 500px) {
    .statement-container {
        padding: 25px 20px;
      
        .statement-image {
            float: none;
            width: 100%;
            margin: auto;
        
        }

        .statement-text {
            margin-top: 10px;
            h2 {
                color: #333;
                font-family: 'Roboto', sans-serif;
                font-size: 24px;
            }
        
            p {
                text-align: justify;
                color: #666;
                line-height: 1.6;
                font-family: 'Open Sans', sans-serif;
                margin-bottom: 1rem;
            }
          }
    }
}
  