@import "./variables";

.header {
    position: sticky;
    top: 0;
    z-index: 2000; /* Adjust this value to make sure it's above other content */
    transition: transform 0.6s ease;
    background-color: $primary;

    padding: 2px 0;
    width: 100%;
    height: 15%;
    color: $white;

    .navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: auto;
        height: 23%;

        .logo {
            // height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // width: 220px;
            width: 25%;

            img {
                padding: 5px 0;
                width: 25%;
            }
            h1{
                margin-top: 20px;
            }
        }

        .navlinks {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;

            a {
                color: white;
                text-decoration: none;
                font-size: large;
                // padding: 0 5px;
                // text-align: center;
            }

            a:hover {
                // color: $accent;
                border-bottom: solid white 3px;
            }
        }

    }

    .gisnavigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 90%;
        margin: auto;
        height: 23%;

        .logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 25%;

            img {
                padding: 5px 0;
                width: 8vh;
            }
            h1{
                font-size: 24px;
            }
        }

        .navlinks {
            width: 60%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;

            a {
                color: white;
                text-decoration: none;
                font-size: large;
                // padding: 0 5px;
                // text-align: center;
            }

            a:hover {
                // color: $accent;
                border-bottom: solid white 3px;
            }
        }

    }
}

.banner {
    text-align: center;
    width: 100%;
    background-color: $primary;
    color: white;
    font-weight: 700;
    font-size: 28px;
    max-height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nobg {
    background: none;
    color: $primary;

    .navigation {

        .navlinks {
            a {
                color: $primary;
                text-shadow: 1px 1px white;
            }

            a:hover {
                // color: $accent;
                border-bottom: solid $primary 3px;
            }
        }

    }

    .gisnavigation {
        .navlinks {
            a {
                color: $primary;
                text-shadow: 1px 1px white;
            }

            a:hover {
                // color: $accent;
                border-bottom: solid $primary 3px;
            }
        }

    }
}

@media screen and (max-width: 1300px) {
    .header {
        .navigation { 
            .navlinks {
                width: 70%;
            }
        }

        .gisnavigation { 
            .navlinks {
                width: 70%;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .header {
        .navigation { 
            .navlinks {
                display: none;
            }
        }

        .gisnavigation { 
            .navlinks {
                display: none;
            }
        }
    }
}

@media screen and (min-width: 800px) {
    .header {
        .navigation { 
            .menu {
                display: none;
            }
        }
        .gisnavigation { 
            .menu {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .header {
        .navigation {   
            .logo {
                width: 170px;
                img {
                    max-height: 40px;
                }
            }
        }

        .gisnavigation {   
            .logo {
                width: 170px;
                img {
                    max-height: 40px;
                }
            }
        }
    }
}