@import "./variables";

.tendercareer-posting {
    width: 90%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  
    &__heading {
      text-align: center;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
  
    &__table {
      width: 100%;
      border-collapse: collapse;
      font-size: 16px;
      overflow-x: auto; /* Ensure table can be scrolled horizontally if too wide */
  
      th,
      td {
        padding: 12px 15px;
        border: 1px solid #ddd;
        text-align: left;

        img {
            width: 100%;
        }
      }
      .deadline {
        min-width: 85px;
      }
  
      th {
        background-color: $primary;
        color: white;
        font-weight: bold;
      }
  
      td {
        background-color: white;
      }
  
      tbody tr:nth-child(even) {
        background-color: #f2f2f2;
      }
  
      tbody tr:hover {
        background-color: #ddd;
      }
    }
  
    &__link {
      display: inline-block;
      padding: 8px 12px;
      background-color: $primary;
      color: white;
      border-radius: 5px;
      text-decoration: none;
      font-weight: bold;
  
      &:hover {
        background-color: $secondary;
      }
    }
  
    /* Responsive styles */
    @media (max-width: 768px) {
      &__heading {
        font-size: 20px;
      }
  
      &__table {
        font-size: 14px;
      }
  
      th,
      td {
        padding: 10px;
      }
  
      /* Stack table rows for smaller devices */
      table, thead, tbody, th, td, tr {
        display: block;
      }
  
      thead {
        display: none;
      }
  
      tbody tr {
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        border: 1px solid #ddd;
      }
  
      td {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border: none;
        position: relative;
        padding-left: 50%;
        text-align: left;
      }
  
      td::before {
        content: attr(data-label);
        position: absolute;
        left: 10px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
        color: #555;
      }
    }
  }