@import "./variables";

.contactus {
    width: 85%;
    margin: 0 auto;

    h2 {
        text-align: center;
        color: $primary;
    }

    .centerheading {
        display: flex;
        justify-content: center;

        .heading {
            color: $primary;
        }

        .decorated-heading {
            position: relative;
            display: inline-block;
        }
        
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $primary;
            transform: translateY(-50%);
        }
        
        .decorated-heading::before {
            left: -40px; 
        }
        
        .decorated-heading::after {
            right: -40px;
        }
    }
    
    .contactInfo {
        width: 100%;
        margin: auto;
        margin-bottom: 40px;
        text-align: center;

        .offices {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
            gap: 1rem;
            
            .office {
                width: 100%;
                height: auto;
                // box-shadow: 1px 1px $secondary;
                border-radius: 15px;

                .officedetails {
                    width: 90%;
                    margin: auto;
                    border-radius: 15px 15px 0 0;
                    border: solid $primary 1px;
                }

                .map-container {
                    width: 100%;
                    height: 200px;
                    
                    iframe {
                        width: 90%;
                        border-radius: 0 0 15px 15px;
                        height: 100%;
                        margin: auto;
                        border: solid $primary 1px;
                    }
                }
            }        
        }
    }
    p {
        font-size: 20px;
        margin-bottom: 10px;
        color: #555;
    }
    // form {
    //     padding: 1em 0;
    //     width: 85%;
    //     margin: auto;

    //     .formGroup {
    //         width: 85%;
    //         margin: auto;
    //         margin-bottom: 15px;
    //     }
    //     .submit {
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //     }
    // }
    // label: {
    //     margin-bottom: '5px';
    //     font-size: '20px';
    //     color: '#333';
    // }
    // input {
    //     padding: 10px;
    //     font-size: 16px;
    //     border-radius: 5px;
    //     border: 1px solid #ccc;
    //     width: 100%;
    // }
    // textarea {
    //     padding: 10px;
    //     font-size: 16px;
    //     border-radius: 5px;
    //     border: 1px solid #ccc;
    //     width: 100%;
    // }

    .sendmessage-container {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 70%;
        margin: 1rem auto;
    
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }

        h6 {
          text-align: center;
        }
    
        .form-group {
            margin-bottom: 25px;
            width: 70%;
            margin: auto;
    
            label {
            display: block;
            margin-bottom: 25px;
            font-weight: bold;
            }

            .passwordinfo {
              font-size: 12px;
              color: red;
              margin-top: -15px;
            }
    
            // input, select {
            // width: 100%;
            // padding: 10px;
            // border: 1px solid #ccc;
            // border-radius: 5px;
            // margin-bottom: 25px;
            // }

            input, select {
              width: 100%;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 5px;
              margin-bottom: 25px;
              box-sizing: border-box; /* Ensures consistent width with padding */
              font-size: 16px; /* Set the font size to be the same */
            }

            textarea {
                padding: 20px;
                font-size: 16px;
                border-radius: 5px;
                border: 1px solid #ccc;
                width: 100%;
            }
        }
    
        .submit-btn {
            display: block; 
            width: 50%;  /* Set button width */
            margin: 25px auto;  /* Center button with margin auto (top-bottom 25px, left-right auto) */
            padding: 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
          
            &:hover {
              background-color: #0056b3;
            }
          }
    }
}

@media screen and (max-width: 580px) {
    .contactus { 
        .contactInfo {
            width: 100%;
            .offices {
                grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
                gap: 2rem;
                
                .office {
                    width: 100%;
                    // box-shadow: 1px 1px $secondary;
                    border-radius: 15px;
    
                    .officedetails {
                        width: 90%;
                        margin: auto;
                        border-radius: 15px 15px 0 0;
                        border: solid $primary 1px;
                    }
    
                    .map-container {
                        width: 100%;
                        height: 200px;
                        
                        iframe {
                            width: 90%;
                            border-radius: 0 0 15px 15px;
                            height: 100%;
                            margin: auto;
                            border: solid $primary 1px;
                        }
                    }
                }        
            }
        }
    }
}