@import "../variables";

.careers {
    width: 85%;
    margin: auto;

    h2 {
        text-align: center;
        color: $primary;
    }

    .centerheading {
        display: flex;
        justify-content: center;

        .heading {
            color: $primary;
        }

        .decorated-heading {
            position: relative;
            display: inline-block;
        }
        
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $primary;
            transform: translateY(-50%);
        }
        
        .decorated-heading::before {
            left: -40px; 
        }
        
        .decorated-heading::after {
            right: -40px;
        }
    }

    .tendercareer-posting {
        width: 90%;
        margin: 0 auto;
        padding: 20px;
        background-color: #f9f9f9;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      
        &__heading {
          text-align: center;
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      
        &__table {
          width: 100%;
          border-collapse: collapse;
          font-size: 16px;
          overflow-x: auto; /* Ensure table can be scrolled horizontally if too wide */
      
          th,
          td {
            padding: 12px 15px;
            border: 1px solid #ddd;
            text-align: left;

            img {
                width: 100%;
            }
          }
          .deadline {
            min-width: 85px;
          }
      
          th {
            background-color: $primary;
            color: white;
            font-weight: bold;
          }
      
          td {
            background-color: white;
          }
      
          tbody tr:nth-child(even) {
            background-color: #f2f2f2;
          }
      
          tbody tr:hover {
            background-color: #ddd;
          }
        }
      
        &__link {
          display: inline-block;
          padding: 8px 12px;
          background-color: $primary;
          color: white;
          border-radius: 5px;
          text-decoration: none;
          font-weight: bold;
      
          &:hover {
            background-color: $secondary;
          }
        }
      
        /* Responsive styles */
        @media (max-width: 768px) {
          &__heading {
            font-size: 20px;
          }
      
          &__table {
            font-size: 14px;
          }
      
          th,
          td {
            padding: 10px;
          }
      
          /* Stack table rows for smaller devices */
          table, thead, tbody, th, td, tr {
            display: block;
          }
      
          thead {
            display: none;
          }
      
          tbody tr {
            margin-bottom: 15px;
            display: flex;
            flex-direction: column;
            border: 1px solid #ddd;
          }
      
          td {
            display: flex;
            justify-content: space-between;
            padding: 10px;
            border: none;
            position: relative;
            padding-left: 50%;
            text-align: left;
          }
      
          td::before {
            content: attr(data-label);
            position: absolute;
            left: 10px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
            font-weight: bold;
            color: #555;
          }
        }
      }
      
      

}

@media screen and (min-width: 1545px) {
    .home {

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .home {

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }

                        .extra-item {
                            grid-column-start: 1; /* Start from the first column */
                            grid-column-end: -1; /* Span all columns */
                            justify-self: center; /* Center the item horizontally */
                            width: 50%;
                            /* Additional styles for the extra grid item */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .home {
        .landingscreen {   
            .content {
                padding-top: 2rem;
                .techimage {
                    
                    img {
                        // max-width: 1500px;
                        width: 90%;
                        margin-bottom: 12%;
                        // min-width: 350px;
                    }
                }
            }
        }

        .main1 {
            .main1bg {
                .section1 {
                    width: 85%;
                    .intro {
                    }
                }
                .section2 {
                    .services {
                        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
                    }
                }
            }
        }

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen  and (max-width: 830px) {
    .home {
        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .extra-item {
                            grid-column-start: auto;
                            grid-column-end: auto;
                            justify-self: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .home {
        .landingscreen {   
            .content {
                .techimage {
                    img {
                        margin-bottom: 22%;
                    }
                }
            }
        }

        .main1 {
            .main1bg {
                .section1 {
                    width: 85%;
                    .intro {
                    }
                }
                .section2 {
                    .services {
                        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
                    }
                }
            }
        }

        .main2 {
            width: 100%;
            .main2bg {
                .whyus {
                    .container {
                        .reason {
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            height: 10px;
                        }

                        .extra-item {
                            grid-column-start: 1; /* Start from the first column */
                            grid-column-end: -1; /* Span all columns */
                            justify-self: center; /* Center the item horizontally */
                            width: 50%;
                            /* Additional styles for the extra grid item */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 705px) {
    .home {
        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .extra-item {
                            grid-column-start: auto;
                            grid-column-end: auto;
                            justify-self: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 640px) {
    .home {
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            width: 3%;
        }

        .landingscreen {   
            .content {
                .techimage {
                    img {
                        margin-bottom: 0;
                        max-width: 100px;
                        min-width: 30%;
                        }
                }
            }
        }

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .nodata {
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}