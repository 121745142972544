@import "./variables";

.services {
    width: 85%;
    margin: 0 auto;

    h2 {
        text-align: center;
        color: $primary;
    }

    .centerheading {
        display: flex;
        justify-content: center;

        .heading {
            color: $primary;
        }

        .decorated-heading {
            position: relative;
            display: inline-block;
        }
        
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $primary;
            transform: translateY(-50%);
        }
        
        .decorated-heading::before {
            left: -40px; 
        }
        
        .decorated-heading::after {
            right: -40px;
        }
    }

    .serviceitems {
        width: 100%;
        margin: 30px auto;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
        gap: 2px;
        background-color: $secondary;
        
        .service {
            min-height: 200px;
            border-radius: 15px 0;
            background-color: $white;
            padding: 0 2rem;

            .imgcon {
                width: 100%;
                margin: 1.5rem auto;

                img {
                    display: block;
                    width: 30%;
                    margin: auto;
                }
            }

            .placeholder {
                width: 100%;
                height: 100%;
            }

        }        
    }
}

@media screen and (max-width: 805px) {
    .services {
        .serviceitems {
            grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        }
    }
}

@media screen and (max-width: 500px) {
    .services {
        .serviceitems {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            .service {
                padding: 0 10px; 

                .placeholder {
                    display: none;
                }
            } 
        }
    }
}