.notfound {
    width: 85%;
    margin: 10% auto;
    max-width: 400px;
    text-align: center;

    h1 {
        font-size: 6rem;
        color: #333;
    }
    
    p {
        font-size: 1.2rem;
        color: #666;
    }

    .back {
        display: flex;
        justify-content: space-between;
        a {
            text-decoration: none;
            color: #007BFF;
        }
        a:hover {
            text-decoration: underline;
        }
    }
}