@import "./variables";

.dropdownContainer {
    position: relative;
    display: inline-block;
  }
  .textDropdown {
    a {
        padding-right: 5px;
    }
  }
  .dropdownMenu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: $primary;
    box-shadow: '0 8px 16px rgba(0,0,0,0.2)';
    z-index: 1000;
    width: max-content;
    .menuItem {
        display: block;
        justify-content: left;
        padding: 12px 16px;
        text-decoration: 'none';
        border-bottom: solid 1px $white;
        font-weight: 500;
        font-size: medium;
    }
}

.nobg {
    .dropdownMenu {
        background-color: $white;
        .menuItem {
            display: block;
            justify-content: left;
            padding: 12px 16px;
            text-decoration: 'none';
            border-bottom: solid 1px $white;
            border-bottom: solid 1px $primary;
        }
      }
  }