@import "./variables";

.image {
    width: 100%;
    height: 20vh;
    background-image: url("../assets/imgs/waterabout.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    div {
        width: 100%;
        height: 100%;
        font-size:xx-large;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($primary, 0.5);
        color: white;
    }
    
}

.image2 {
  width: 100%;
  height: 20vh;
  background-image: url("../assets/imgs/waterservice.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  div {
      width: 100%;
      height: 100%;
      font-size:xx-large;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($primary, 0.5);
      color: white;
  }
  
}


.aboutus {
    width: 85%;
    margin: 0 auto;
      

    h2 {
        text-align: center;
        color: $primary;
    }

    .centerheading {
        display: flex;
        justify-content: center;

        .heading {
            color: $primary;
        }

        .decorated-heading {
            position: relative;
            display: inline-block;
        }
        
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $primary;
            transform: translateY(-50%);
        }
        
        .decorated-heading::before {
            left: -40px; 
        }
        
        .decorated-heading::after {
            right: -40px;
        }
    }
    
    .aboutInfo {
        margin-bottom: 40px;
        justify-self: start;
    }
    p {
        font-size: 20px;
        margin-bottom: 10px;
        color: #555;
    }

    .vision-mission-container {
        display: grid;
        grid-template-columns: 1fr 1fr; // Two equal columns
        gap: 20px; // Gap between the boxes
        max-width: 1200px; // Limit the width for better design
        margin: 40px auto; // Center the container
      
        .box {
        //   background-color: $primary; // Light blue to give a warm water-related feel
          background-color: rgba(#0077be, 0.7);
          padding: 30px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
          text-align: center;
          color: white;
      
          h2 {
            font-size: 24px;
            color: white;
            margin-bottom: 15px;
          }
      
          p {
            font-size: 20px;
            line-height: 1.6;
            color: white;
            // color: #333; // Dark text for readability
          }

          ul {
            text-align: left;
          }
      
          &:hover {
            background-color: #e0f0fa; // A bit darker on hover
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // More prominent shadow on hover
            transition: all 0.3s ease;

            h2 {
                color: #0077be;
            }

            p {
                color: #333; // Dark text for readability
              }
          }
        }  
      
        // Mobile responsiveness
        @media (max-width: 768px) {
          grid-template-columns: 1fr; // Single column layout on smaller screens
        }
      }

      .vision-values-container {
        display: grid;
        grid-template-columns: 1fr 1fr; // Two equal columns
        gap: 20px; // Gap between the boxes
        max-width: 1200px; // Limit the width for better design
        margin: 40px auto; // Center the container
      
        // .box {
        //   background-color: #f4f9fd; // Light blue to give a warm water-related feel
        //   padding: 30px;
        //   border-radius: 10px;
        //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
        //   text-align: center;
      
        //   h2 {
        //     font-size: 24px;
        //     color: #0077be; // Water blue color for heading
        //     margin-bottom: 15px;
        //   }
      
        //   p {
        //     font-size: 16px;
        //     line-height: 1.6;
        //     color: #333; // Dark text for readability
        //   }

        //   ul {
        //     text-align: left;
        //   }
      
        //   &:hover {
        //     background-color: #e0f0fa; // A bit darker on hover
        //     box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); // More prominent shadow on hover
        //     transition: all 0.3s ease;
        //   }
        // }

        .values-box {
            background-color: #f4f9fd;
            padding: 30px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            text-align: left;
            transition: all 0.3s ease;
          
            h2 {
              font-size: 24px;
              color: #0077be;
              margin-bottom: 20px;
            }
          
            &:hover {
              background-color: #e0f0fa;
              box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
            }
          
            .fancy-list {
              list-style-type: none; // Remove default list style
              padding: 0;
              margin: 0;
          
              li {
                position: relative;
                padding-left: 35px; // Space for the custom icon
                margin-bottom: 15px;
                font-size: 20px;
                color: #333;
                display: flex; // Align icon and text side by side
                align-items: center;
          
                // FontAwesome Icon Styles
                svg {
                  color: #0077be; // Change icon color to water blue
                  font-size: 24px; // Size of the icon
                  margin-right: 10px; // Space between icon and text
                }
          
                // Hover effect
                &:hover {
                  color: #0077be;
                  transform: translateX(10px); // Slight shift to the right
                }
              }
            }
        }
          
          
      
        // Mobile responsiveness
        @media (max-width: 768px) {
          grid-template-columns: 1fr; // Single column layout on smaller screens
        }
      }

    .service_area {
        width: 100%;
        .map {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
}