@import "./variables";

.menutab {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: fit-content;
    z-index: 1100; /* Adjust this value to make sure it's above other content */
    transition: transform 0.6s ease;
    background-color: rgba($color: $primary, $alpha:0.95);

    padding: 1rem 0;
    color: $white;
    .navigation {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        width: 85%;
        margin: auto;
        height: 100%;

        .catchphrase {
            width: 100%;
            text-align: center;
            color: $accent;
        }

        .website {
            margin: 5% 0;
            padding: 1rem 0;
            border-top: solid white;
            border-bottom: solid white;
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            .pages, .sections {
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                text-decoration: none;

                a {
                    color: white;
                    text-decoration: none;
                    line-height: 20px;
                    margin-left: 1rem;
                }

                p {
                    line-height: 20px; 
                }

            }

            .spages {
                margin-left: 1rem;
                
                a {
                    font-size: 12px;
                    line-height: 18px;
                }
            }

            .reachus {
                padding-left: 1rem;
                a {
                    line-height: 24px;
                    color: white;
                    text-decoration: none;
                }
                p {
                    line-height: 20px;
                }
                .contsloc {
                    * {
                        width: 75%;
                        margin: auto;
                    }
                    display: flex;

                    svg{
                        width: 1rem;
                    }
                }
            }

            .heads{
                font-weight: bold;
            }
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 95%;
            margin: 8% 0 16% 0;
            img {
                width: 30%;
                margin: 8% 10% 0 0;
            }

            h1 {
                font-size: large;
            }
        }

        .close {
            padding: 5%;
            text-align: center;
            border: solid white 1px;
            border-radius: 50%;
            svg {
                font-size: x-large;
            }
        }
    }

    .gisnavigation {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        width: 85%;
        margin: auto;
        height: 100%;

        .catchphrase {
            width: 100%;
            text-align: center;
            color: $accent;
        }

        .website {
            margin: 5% 0;
            padding: 1rem 0;
            border-top: solid white;
            border-bottom: solid white;
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            a {
                color: white;
                text-decoration: none;
                line-height: 36px;
                margin-left: 1rem;
            }

            .pages, .sections {
                padding-left: 1rem;
                display: flex;
                flex-direction: column;
                text-decoration: none;
            }

            .reachus {
                padding-left: 1rem;
                a {
                    line-height: 24px;
                }
                .contsloc {
                    * {
                        width: 75%;
                        margin: auto;
                    }
                    display: flex;

                    svg{
                        width: 1rem;
                    }
                }
            }

            .heads{
                font-weight: bold;
            }
        }

        .logo {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 60%;
            margin: 8% 0 16% 0;
            img {
                width: 30%;
                margin: 8% 10% 0 0;
            }

            h1 {
                font-size: large;
            }
        }

        .close {
            padding: 5%;
            text-align: center;
            border: solid white 1px;
            border-radius: 50%;
            svg {
                font-size: x-large;
            }
        }
    }
    
}

.bg {
    background: none;
}

.scrolledByVh {
    transform: translateY(-100%);
  }

@media screen and (min-width: 350px) {
    .menutab {
        * {
            margin: 0;
            font-size:small;
        }
    }
}