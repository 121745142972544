@import "../variables";

.gis {
    .map {
        position: relative;
        width: 100%;
        height: 90vh;
        overflow: hidden;
    
        .map_container {
            width: 100%;
            height: 100%;
            position: absolute;  /* Ensures it fills the parent completely */
            top: 0;
            left: 0;

            /* Styles for map controls */
            .ol-control {
                position: absolute;
                bottom: 20px;            /* Distance from the top */
                left: 10px;
                width: fit-content;
                background-color: rgba(255, 255, 255, 0.8);  /* Light background for visibility */
                border-radius: 5px;
                box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
                padding: 1px;
                margin: 1px;  /* Space around controls */
                transition: background-color 0.3s ease;
        
                &:hover {
                    background-color: rgba(255, 255, 255, 1);  /* Full opacity on hover */
                }
        
                button {

                    background-color: $primary; /* Use your primary color */
                    border: none;
                    color: white;
                    padding: 4px 6px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: background-color 0.3s ease;
        
                    &:hover {
                        background-color: darken($primary, 10%); /* Darken on hover */
                    }
        
                    &:active {
                        transform: scale(0.95); /* Slight scaling effect on click */
                    }
                }

                .ol-zoom-in {
                    margin-left: 2em;
                }

                .ol-zoom-out {
                    margin-left: 0.4em;
                    }

            }
        }
    
    }
    /* Basemap select styles */
    .basemaps {
        position: absolute;    /* Position relative to the map */
        bottom: 20px;            /* Distance from the top */
        right: 10px;          /* Distance from the right */
        z-index: 10;          /* Ensure it appears on top of the map */
        background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white */
        border-radius: 8px;   /* Rounded corners */
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Soft shadow for depth */
        padding: 15px;        /* Inner spacing */
        max-width: 250px;     /* Limit the width for better readability */
        display: flex;        /* Flexbox layout */
        flex-direction: column; /* Stack items vertically */
        gap: 10px;            /* Space between each Basemap item */
        
        &:hover {
            background-color: rgba(255, 255, 255, 1); /* Full opacity on hover */
        }
    }
    
    .basemapselect {
        display: flex;         /* Use flexbox for horizontal alignment */
        align-items: center;   /* Center items vertically */
        cursor: pointer;      /* Pointer cursor on hover */
        transition: background-color 0.3s ease; /* Smooth background color transition */
    
        input[type="checkbox"] {
            margin-right: 8px; /* Space between checkbox and label */
            accent-color: $primary; /* Change checkbox color to your primary color */
        }
    
        label {
            font-size: 14px;   /* Font size for readability */
            font-weight: 500;  /* Semi-bold font for emphasis */
            color: #333;       /* Dark text color */
            transition: color 0.3s ease; /* Smooth color transition */
            
            &:hover {
                color: $primary; /* Change color on hover */
            }
        }
    
        &.selected {
            background-color: rgba($primary, 0.2); /* Highlight selected items */
            border-radius: 4px; /* Rounded corners for selected items */
        }
    }
    

      
}