@import "./variables";
.singleservicelink {
    
    background-color: white;
    border: solid 2px white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    .slcontent {
        padding: 5px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            width: 30%;
        }
        h5 {
            margin-bottom: 0;
        }
    }

    @media (max-width: 668px) {
        .slcontent {
            padding: 5px 5px;
        }
      }
}

.singleservicelink:hover {
    cursor: pointer;
    background-color: $bg;
    border: solid 1px $primary;
}