@import "./variables";

// Login.scss
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content; // Full height of the viewport
    background-color: #f0f2f5;
    width: 100%;
  
    .login-container {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        //   max-width: 400px;
        width: 30%;
        margin: 1rem auto;
    
        h2 {
            text-align: center;
            margin-bottom: 20px;
        }

        h6 {
          text-align: center;
        }
    
        .form-group {
            margin-bottom: 25px;
            width: 70%;
            margin: auto;
    
            label {
            display: block;
            margin-bottom: 25px;
            font-weight: bold;
            }

            .passwordinfo {
              font-size: 12px;
              color: red;
              margin-top: -15px;
            }
    
            // input, select {
            // width: 100%;
            // padding: 10px;
            // border: 1px solid #ccc;
            // border-radius: 5px;
            // margin-bottom: 25px;
            // }

            input, select {
              width: 100%;
              padding: 10px;
              border: 1px solid #ccc;
              border-radius: 5px;
              margin-bottom: 25px;
              box-sizing: border-box; /* Ensures consistent width with padding */
              font-size: 16px; /* Set the font size to be the same */
            }
        }
    
        .login-btn {
            display: block; 
            width: 50%;  /* Set button width */
            margin: 25px auto;  /* Center button with margin auto (top-bottom 25px, left-right auto) */
            padding: 10px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            transition: background-color 0.3s;
          
            &:hover {
              background-color: #0056b3;
            }
          }
    }
}
  

@media screen and (min-width: 1545px) {
}

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1060px) {
}

@media screen  and (max-width: 830px) {
}

@media screen and (max-width: 767px) {
}

@media screen and (max-width: 705px) {
    .login-page {    
        .login-container {
          width: 90%;
      
          h2 {
            text-align: center;
            margin-bottom: 20px;
          }
      
        //   .form-group {
        //     margin-bottom: 15px;
        //     width: 90%;
        //     margin: auto;
      
        //     label {
        //       display: block;
        //       margin-bottom: 5px;
        //       font-weight: bold;
        //     }
      
        //     input {
        //       width: 100%;
        //       padding: 10px;
        //       border: 1px solid #ccc;
        //       border-radius: 5px;
        //     }
        //   }
      
        //   .login-btn {
        //     width: 100%;
        //     padding: 10px;
        //     background-color: #007bff;
        //     color: white;
        //     border: none;
        //     border-radius: 5px;
        //     cursor: pointer;
        //     transition: background-color 0.3s;
      
        //     &:hover {
        //       background-color: #0056b3;
        //     }
        //   }
        }
    }
}

// @media screen and (max-height: 640px) {
//     .login-page {    
//         .login-container {
//           width: 90%;
      
//           h2 {
//             text-align: center;
//             margin-bottom: 20px;
//           }
      
//           .form-group {
//             margin-bottom: 15px;
//             width: 70%;
//             margin: auto;
      
//             label {
//               display: block;
//               margin-bottom: 5px;
//               font-weight: bold;
//             }
      
//             input {
//               width: 100%;
//               padding: 10px;
//               border: 1px solid #ccc;
//               border-radius: 5px;
//             }
//           }
      
//           .login-btn {
//             width: 100%;
//             padding: 10px;
//             background-color: #007bff;
//             color: white;
//             border: none;
//             border-radius: 5px;
//             cursor: pointer;
//             transition: background-color 0.3s;
      
//             &:hover {
//               background-color: #0056b3;
//             }
//           }
//         }
//     }
// }