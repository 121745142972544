@import "./variables";

.posts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    grid-gap: 1rem;

    .post {
        width: 100%;
        box-sizing: border-box;
        display: grid; // Use grid for layout
        grid-template-columns: 180px 1fr; // Fixed width for the image, flexible space for the content
        gap: 1.5rem; // Space between the image and content
        margin-bottom: 2rem; // Space between posts
        background-color: $bg;
        border: 1px solid gray;
        padding: 16px;
        border-radius: 10px;
      
        .blob {
            width: 100%;
            img {
                display: block;
                width: 100%; // Ensures the image fills its container
                height: 100%; // Maintains aspect ratio
                border-radius: 8px; // Adds rounded corners
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); // Subtle shadow
            }
        }
      
        .detail {
          h3 {
            font-size: 1rem;
            margin: 0;
            color: #333; // Darker text for better readability
          }
      
          p {
            margin: 0.3rem 0;
            color: #555; // Slightly lighter text for supporting details
            line-height: 1.6; // Improves readability
          }
    
          button {
            // margin-top: 0.5rem;
            // background-color: #007bff; // Blue background
            // color: white;
            // border: none;
            // padding: 0.5rem 1rem;
            // border-radius: 4px;
            // cursor: pointer;
            // font-size: 1rem;
        
            &:hover {
              background-color: #0056b3; // Darker blue on hover
              color: white;
              border: none;
              padding: 0.2rem 0.5rem;
              border-radius: 4px;
            }
          }
        }
      
        // Responsive layout for smaller screens
        @media (max-width: 768px) {
          grid-template-columns: 1fr; // Stacks items vertically
          text-align: center; // Centers text for better presentation
      
          .blob {
            margin-bottom: 1rem; // Adds space below the image
          }
        }
      }
}

// Responsive layout for smaller screens
@media (max-width: 768px) {
    .posts {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }
  