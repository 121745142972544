@import "./variables";

.image {
    width: 100%;
    height: 20vh;
    background-image: url("../assets/imgs/boardroom.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    div {
        width: 100%;
        height: 100%;
        font-size: xx-large;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($primary, 0.5);
        color: white;
    }
    
}


.management {
    width: 85%;
    margin: 0 auto;
    background-image: url("../assets/imgs/technology.jpg");
    background-position: center;
    background-size: contain;

    h2 {
        text-align: center;
        color: $primary;
    }

    .transpbg {
        background-color: rgba($color: white, $alpha: 0.94);
        .persons {
            width: 100%;
            margin: auto;
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            margin: 2rem auto;
            
            .person {
                width: 100%;
                height: 100%;
                max-width: 300px;
                margin: auto;
                border-radius: 10px;
                background-color: aliceblue;
                box-shadow: 10px 6px 10px rgba(0, 0, 0, 0.1);
                border: 1px solid $lightgray;
    
                img {
                    width: 100%; /* Ensures the image takes up the entire width of the grid item */
                    height: 310px; /* Ensures the image takes up the entire height of the grid item */
                    object-fit:cover ;
                }
    
                .details {
                    padding: 0 1rem;
                    text-align: center;
                }
            }

            .person:hover {
                cursor: pointer;
                border: 1px solid $primary;
                background-color: rgba($primary, 1);

                .details {
                    color: $white;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .image {
      width: 100%;
      height: 12vh;
      
      div {
          width: 100%;
          height: 100%;
          font-size: large;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba($primary, 0.5);
          color: white;
      }  
    }

    .management {
        .persons {

            .person {
                max-width: 300px;
                margin: 2px 0 0 3px;
                border-radius: 5px;
                background-color: aliceblue;
    
                img {
                    width: 100%; /* Ensures the image takes up the entire width of the grid item */
                    height: 100%; /* Ensures the image takes up the entire height of the grid item */
                    object-fit: cover;
                }
    
                .details {
                    padding: 0 1rem;
                    text-align: center;
                }
            }
        }
    }
    
  }