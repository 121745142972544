@import "./variables";

.button-white {
    width: fit-content;
    padding: 0 10px;
    border: white solid 1px;
    border-radius: 15px;
    font-weight: 600;
    background-color: $white;
    color: $secondary;
}

.button-white:hover {
    border: $primary solid 2px;
    color: $primary;
    font-weight: 700;
    cursor: pointer;
    p{
        text-shadow: 0.5px 0.5px $accent;
    }
}

.button-blue {
    width: fit-content;
    padding: 0 10px;
    border: white solid 1px;
    border: $primary solid 2px;
    border-radius: 15px;
    font-weight: 600;
    background-color: $primary;
    color: $white;
}

.button-blue:hover {
    background-color: $white;
    color: $primary;
    font-weight: 700;
    cursor: pointer;
    p{
        text-shadow: 0.5px 0.5px $accent;
    }
}