@import "./variables";

.image {
    width: 100%;
    height: 20vh;
    background-image: url("../assets/imgs/boardroom.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
    div {
        width: 100%;
        height: 100%;
        font-size: xx-large;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba($primary, 0.5);
        color: white;
    }
    
}


.board {
    width: 85%;
    margin: 0 auto;

    h2 {
        text-align: center;
        color: $primary;
    }

    .persons {
        width: 100%;
        margin: auto;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        margin: 2rem auto;
        
        .person {
            width: 100%;
            height: 100%;
            max-width: 300px;
            margin: auto;
            border-radius: 5px;
            background-color: aliceblue;
            box-shadow: 10px 6px 10px rgba(0, 0, 0, 0.1);
            border: 1px solid $lightgray;

            img {
                width: 100%; /* Ensures the image takes up the entire width of the grid item */
                height: 100%; /* Ensures the image takes up the entire height of the grid item */
                object-fit: cover;
            }

            .details {
                padding: 0 1rem;
                text-align: center;
                position: relative;
                transform: translateY(30%); /* Initially pushed down */
                transition: transform 0.4s ease; /* Smooth transition for upward movement */
              
                h3 {
                  color: $primary;
                  margin: 6px auto;
                }
              
                .designation {
                //   font-style: italic;
                  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                  font-size: larger;
                  font-weight: 600;
                  opacity: 1;
                  margin: 10px auto;
                }
              
                .academics {
                    color: $white;
                    display: block;
                    opacity: 0;
                    transform: translateY(20px); /* Start 20px below */
                    transition: opacity 0.4s ease, transform 0.4s ease;
                    font-size: small;
                    // line-height: px;
                }
              
            }

            &:hover {
                background-color: rgba($primary, 1);

                .details {
                    transform: translateY(0); /* Move the entire .details div upward to reveal .academics */
                  cursor: pointer;
                  border: 1px solid $primary;
              
                  color: $white;
              
                  h3 {
                    color: $white;
                  }
              
                  .academics {
                    opacity: 1;
                    transform: translateY(0); /* Bring .academics into place */
                  }
                }
            }
              
              
              
        }

        // .person:hover {
        //     .details {
        //         .designation {
        //             display: none;
        //         }
        //         .academics {
        //             display: block;
        //         }
        //     }
        // }
    }
}

@media screen and (max-width: 500px) {
    .image {
      width: 100%;
      height: 12vh;
      
      div {
          width: 100%;
          height: 100%;
          font-size: large;
          font-weight: 600;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba($primary, 0.5);
          color: white;
      }  
    }

    .board {
        .persons {

            .person {
                max-width: 300px;
                margin: 2px 0 0 3px;
                border-radius: 5px;
                background-color: aliceblue;
    
                img {
                    width: 100%; /* Ensures the image takes up the entire width of the grid item */
                    height: 100%; /* Ensures the image takes up the entire height of the grid item */
                    object-fit: cover;
                }
    
                .details {
                    padding: 0 1rem;
                    text-align: center;
                }
            }
        }
    }
    
  }