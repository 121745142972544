.quickservices {
    width: 100%;
    min-height: 100vh;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));

    // .carousel {
    //     img {
    //         width: 100%;
    //         height: 100%;
    //     }
    // }

    .quickbuttons {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        padding: 20px 25px 10px 25px;
        gap: 2rem;

    }
}

@media screen and (min-width: 1300px) {
    .quickservices {
        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
        .quickbuttons {
            height: 75vh;
        }
    }
}

@media screen and (max-width: 900px) {
    .quickservices {
        grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
        .quickbuttons {
        }
    }
}

@media screen and (max-width: 560px) {
    .quickservices {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

        .carousel {
            height: 50vh;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .quickbuttons {
        }
    }
}