.newsevents {
    width: 65%;
    margin: auto;
}

@media (max-width: 768px) {
    .newsevents {
        width: 85%;
    }
}
