.single-post {
    display: flex;
    flex-direction: column;  /* Stack content vertically */
    justify-content: flex-start;  /* Align items to the top */
    align-items: center;  /* Center content horizontally */
    min-height: 100vh;  /* Ensure full screen height */
    background-color: #f9f9f9;
    padding: 2rem;

    .post-container {
        max-width: 800px;
        width: 100%;
        background: #ffffff;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        padding: 2rem;
        margin-bottom: 1.5rem;  /* Space between the content and the button */

        .post-title {
            font-size: 2rem;
            font-weight: bold;
            color: #333;
            text-align: center;
            margin-bottom: 1rem;
        }

        .post-date {
            font-size: 0.9rem;
            color: #888;
            text-align: center;
            margin-bottom: 1.5rem;
        }

        .post-image {
            margin: 1.5rem 0;

            img {
                width: 100%;
                height: auto;
                object-fit: cover;
                border-radius: 10px;
            }
        }

        .post-details {
            font-size: 1rem;
            line-height: 1.6;
            color: #555;
            margin: 1.5rem 0;

            p {
                margin-bottom: 1rem;
            }
        }
    }

    .back-button-container {
        display: flex;
        justify-content: center;  /* Center the button horizontally */
        width: 100%;  /* Ensure it takes full width of the container */
        padding: 1rem 0;  /* Adds some padding for space above and below button */
        position: relative;  /* To position the button below the content */
        bottom: 0;

        .back-button {
            padding: 0.5rem 1rem;
            font-size: 1rem;
            color: #ffffff;
            background-color: #007bff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-decoration: none;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: #0056b3;
            }
        }
    }
}
