@import "../variables";

.tenders {
    width: 85%;
    margin: auto;

    h2 {
        text-align: center;
        color: $primary;
    }

    .centerheading {
        display: flex;
        justify-content: center;

        .heading {
            color: $primary;
        }

        .decorated-heading {
            position: relative;
            display: inline-block;
        }
        
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            position: absolute;
            top: 50%;
            width: 30px;
            height: 2px;
            background-color: $primary;
            transform: translateY(-50%);
        }
        
        .decorated-heading::before {
            left: -40px; 
        }
        
        .decorated-heading::after {
            right: -40px;
        }
    }         

}

@media screen and (min-width: 1545px) {
    .home {

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .home {

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }

                        .extra-item {
                            grid-column-start: 1; /* Start from the first column */
                            grid-column-end: -1; /* Span all columns */
                            justify-self: center; /* Center the item horizontally */
                            width: 50%;
                            /* Additional styles for the extra grid item */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1060px) {
    .home {
        .landingscreen {   
            .content {
                padding-top: 2rem;
                .techimage {
                    
                    img {
                        // max-width: 1500px;
                        width: 90%;
                        margin-bottom: 12%;
                        // min-width: 350px;
                    }
                }
            }
        }

        .main1 {
            .main1bg {
                .section1 {
                    width: 85%;
                    .intro {
                    }
                }
                .section2 {
                    .services {
                        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
                    }
                }
            }
        }

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
                        .reason {
                            border: white solid;
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media screen  and (max-width: 830px) {
    .home {
        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .extra-item {
                            grid-column-start: auto;
                            grid-column-end: auto;
                            justify-self: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .home {
        .landingscreen {   
            .content {
                .techimage {
                    img {
                        margin-bottom: 22%;
                    }
                }
            }
        }

        .main1 {
            .main1bg {
                .section1 {
                    width: 85%;
                    .intro {
                    }
                }
                .section2 {
                    .services {
                        grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
                    }
                }
            }
        }

        .main2 {
            width: 100%;
            .main2bg {
                .whyus {
                    .container {
                        .reason {
                            *  {
                                width: 72%;
                            }
                        }
                        .nodata {
                            height: 10px;
                        }

                        .extra-item {
                            grid-column-start: 1; /* Start from the first column */
                            grid-column-end: -1; /* Span all columns */
                            justify-self: center; /* Center the item horizontally */
                            width: 50%;
                            /* Additional styles for the extra grid item */
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 705px) {
    .home {
        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .extra-item {
                            grid-column-start: auto;
                            grid-column-end: auto;
                            justify-self: auto;
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-height: 640px) {
    .home {
        .decorated-heading::before,
        .decorated-heading::after {
            content: "";
            width: 3%;
        }

        .landingscreen {   
            .content {
                .techimage {
                    img {
                        margin-bottom: 0;
                        max-width: 100px;
                        min-width: 30%;
                        }
                }
            }
        }

        .main2 {
            .main2bg {
                .whyus {
                    .container {
                        .nodata {
                            height: 10px;
                        }
                    }
                }
            }
        }
    }
}